import React, { useState } from "react";
import { Modal, Upload, Button, Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import axios from "../../../helpers/axios";
import { successAlert, errorAlert } from "../../../helpers/notification";

const ProductForm = ({ visible, setVisible, getBanners }) => {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [desktopImage, setDesktopImage] = useState([]);
  const [mobileImage, setMobileImage] = useState([]);
  const [carouselThree, setCarouselThree] = useState([]);
  const [link, setLink] = useState("");

  const handleSubmit = () => {
    setLoading(true);
    const formData = new FormData();

    formData.append("name", name);
    formData.append("desktop_image", desktopImage[0]?.originFileObj || "");
    formData.append("mobile_image", mobileImage[0]?.originFileObj || "");
    formData.append("carousel_three", carouselThree[0]?.originFileObj || "");
    formData.append("link", link);

    axios
      .post("/banner", formData, { headers: { authorization: token } })
      .then(() => {
        setName("");
        setDesktopImage([]);
        setMobileImage([]);
        setLink("");
        setLoading(false);
        setVisible(false);
        getBanners();
        successAlert("Add product successful");
      })
      .catch((err) => {
        setLoading(false);
        errorAlert();
      });
  };

  const onHandleSelect = (value) => {
    setName(value);
    switch (value) {
      case "homepage":
        return setLink("/");
      case "about-us":
        return setLink("/about-us");
      case "products":
        return setLink("/products");
      case "news":
        return setLink("/news");
      case "career":
        return setLink("/career");
      case "contact-us":
        return setLink("/contact-us");
      case "contact-form":
        return setLink("/contact-form");
      default:
        return;
    }
  };

  const removeErrorStatus = (rawFiles) => {
    const result = rawFiles.map((files) => ({ ...files, status: "done" }));
    return result;
  };

  return (
    <div>
      <Modal
        destroyOnClose={true}
        title="Add banner"
        visible={visible}
        okText="Submit"
        onOk={handleSubmit}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleSubmit}
          >
            Submit
          </Button>,
        ]}
      >
        <Select
          placeholder="Select page"
          onChange={(value) => onHandleSelect(value)}
          style={{ width: "100%" }}
        >
          <Select.Option value="homepage">Homepage</Select.Option>
          <Select.Option value="about-us">About Us</Select.Option>
          <Select.Option value="products">Products</Select.Option>
          <Select.Option value="news">News</Select.Option>
          <Select.Option value="career">Career</Select.Option>
          <Select.Option value="contact-us">Contact Us</Select.Option>
          <Select.Option value="contact-form">Contact Form</Select.Option>
        </Select>
        <br />
        <br />
        <Upload
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          listType="picture"
          fileList={desktopImage}
          onChange={({ fileList }) =>
            setDesktopImage(removeErrorStatus(fileList))
          }
        >
          <Button>
            <UploadOutlined /> Desktop Banner
          </Button>
        </Upload>
        <br />
        <Upload
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          listType="picture"
          fileList={mobileImage}
          onChange={({ fileList }) =>
            setMobileImage(removeErrorStatus(fileList))
          }
        >
          <Button>
            <UploadOutlined /> Mobile Banner
          </Button>
        </Upload>
        <br />
        {name === "homepage" && (
          <Upload
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            listType="picture"
            fileList={carouselThree}
            onChange={({ fileList }) =>
              setCarouselThree(removeErrorStatus(fileList))
            }
          >
            <Button>
              <UploadOutlined /> Carousel
            </Button>
          </Upload>
        )}
      </Modal>
    </div>
  );
};

export default ProductForm;
