import React, { useState } from "react";
import { Card, Modal, Input, InputNumber, Upload, Button, Select } from "antd";
import {
  EditOutlined,
  FileImageOutlined,
  UploadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Editor } from "@tinymce/tinymce-react";
import ReactHtmlParser from "react-html-parser";

import axios from "../../../helpers/axios";
import { successAlert, errorAlert } from "../../../helpers/notification";
import image from "../../../constants/image";

const { Meta } = Card;

const AboutCard = ({ about, getAbouts }) => {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showUpdateImage, setShowUpdateImage] = useState(false);
  const [position, setPosition] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [lang, setLang] = useState("");
  const [imageList, setImageList] = useState([]);

  const onEditAbout = () => {
    setLoading(true);
    axios
      .put(
        `/about/update/${about.id}`,
        {
          position: position || about.position,
          title: title || about.title,
          description: description || about.description,
          lang: lang || about.lang,
        },
        {
          headers: { authorization: token },
        }
      )
      .then(() => {
        setPosition(1);
        setTitle("");
        setDescription("");
        setLoading(false);
        setVisible(false);
        setLang("");
        getAbouts();
        successAlert("Update about successful");
      })
      .catch(() => {
        setLoading(false);
        errorAlert();
      });
  };

  const onEditAboutImage = () => {
    setLoading(true);
    const formData = new FormData();

    formData.append("image", imageList[0].originFileObj);

    axios
      .put(`/about/update-image/${about.id}`, formData, {
        headers: { authorization: token },
      })
      .then(() => {
        setImageList([]);
        setLoading(false);
        setShowUpdateImage(false);
        getAbouts();
        successAlert("Update about image successful");
      })
      .catch(() => {
        setLoading(false);
        errorAlert();
      });
  };

  const removeErrorStatus = (rawFiles) => {
    const result = rawFiles.map((files) => ({ ...files, status: "done" }));
    return result;
  };

  const onDeleteAbout = () => {
    axios
      .delete(`/about/delete/${about.id}`, {
        headers: { authorization: token },
      })
      .then(() => {
        getAbouts();
        successAlert("Delete about successful");
      })
      .catch(() => {
        errorAlert();
      });
  };

  const handleEditorChange = (e) => {
    setDescription(e.target.getContent());
  };

  return (
    <>
      <Modal
        destroyOnClose={true}
        title="Edit - About Us"
        visible={visible}
        okText="Update"
        onOk={onEditAbout}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={onEditAbout}
          >
            Update
          </Button>,
        ]}
      >
        <InputNumber
          placeholder="Position"
          defaultValue={Number(about.position)}
          onChange={(value) => setPosition(value)}
        />
        <br />
        <br />
        <Select
          placeholder="Select Language"
          defaultValue={about.lang}
          onChange={(value) => setLang(value)}
        >
          <Select.Option value="id">Indonesia</Select.Option>
          <Select.Option value="en">English</Select.Option>
        </Select>
        <br />
        <br />
        <Input
          placeholder="Title"
          defaultValue={about.title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <br />
        <br />
        <Editor
          apiKey="xkovcsork8x0mfeg83j06rvwzqfjctwtx36jho2bsfq8yyak"
          initialValue={about.description}
          init={{
            oninit: "setPlainText",
            height: 300,
            menubar: false,
            plugins: ["advlist autolink lists link image", "paste"],
            toolbar: "undo redo | bullist numlist",
          }}
          onChange={handleEditorChange}
        />
      </Modal>
      <Modal
        destroyOnClose={true}
        title="Edit - About Us Image"
        visible={showUpdateImage}
        okText="Update image"
        onOk={onEditAboutImage}
        onCancel={() => setShowUpdateImage(false)}
        footer={[
          <Button key="back" onClick={() => setShowUpdateImage(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={onEditAboutImage}
          >
            Update image
          </Button>,
        ]}
      >
        <Upload
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          listType="picture"
          fileList={imageList}
          onChange={({ fileList }) => setImageList(removeErrorStatus(fileList))}
        >
          <Button>
            <UploadOutlined /> Image
          </Button>
        </Upload>
      </Modal>
      <Card
        title={
          about.lang === "id" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <img src={image.indonesia} width="24px" alt="indonesia" />
              <p style={{ margin: "0" }}>Bahasa Indonesia</p>
            </div>
          ) : about.lang === "en" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <img src={image.english} width="24px" alt="indonesia" />
              <p style={{ margin: "0" }}>English</p>
            </div>
          ) : (
            <p style={{ margin: "0" }}>Choose Language</p>
          )
        }
        style={{ width: "100%" }}
        cover={<img alt="about" src={about.image} height={220} />}
        actions={[
          <EditOutlined onClick={() => setVisible(true)} key="edit" />,
          <FileImageOutlined
            onClick={() => setShowUpdateImage(true)}
            key="edit-image"
          />,
          <DeleteOutlined onClick={onDeleteAbout} key="delete" />,
        ]}
      >
        <Meta title={about.title} description={about.position} />
        <div>{ReactHtmlParser(about.description)}</div>
      </Card>
    </>
  );
};

export default AboutCard;
