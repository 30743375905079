import React, { useState } from "react";
import { Card, Modal, Input, Upload, Button, Select } from "antd";
import { Editor } from "@tinymce/tinymce-react";
import {
  EditOutlined,
  FileImageOutlined,
  UploadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import ReactHtmlParser from "react-html-parser";

import axios from "../../../helpers/axios";
import { successAlert, errorAlert } from "../../../helpers/notification";
import image from "../../../constants/image";

const { Meta } = Card;

const NewsCard = ({ news, getNews }) => {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showUpdateImage, setShowUpdateImage] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [lang, setLang] = useState("");
  const [imageList, setImageList] = useState([]);

  const onEditNews = () => {
    setLoading(true);
    axios
      .put(
        `/news/update/${news.id}`,
        {
          title: title || news.title,
          description: description || news.description,
          lang: lang || news.lang,
        },
        {
          headers: { authorization: token },
        }
      )
      .then(() => {
        setTitle("");
        setDescription("");
        setLoading(false);
        setVisible(false);
        getNews();
        successAlert("Update news successful");
      })
      .catch(() => {
        setLoading(false);
        errorAlert();
      });
  };

  const onEditNewsImage = () => {
    setLoading(true);
    const formData = new FormData();

    formData.append("image", imageList[0].originFileObj);

    axios
      .put(`/news/update-image/${news.id}`, formData, {
        headers: { authorization: token },
      })
      .then(() => {
        setImageList([]);
        setLoading(false);
        setShowUpdateImage(false);
        getNews();
        successAlert("Update news image successful");
      })
      .catch(() => {
        setLoading(false);
        errorAlert();
      });
  };

  const removeErrorStatus = (rawFiles) => {
    const result = rawFiles.map((files) => ({ ...files, status: "done" }));
    return result;
  };

  const onDeleteNews = () => {
    axios
      .delete(`/news/delete/${news.id}`, {
        headers: { authorization: token },
      })
      .then(() => {
        getNews();
        successAlert("Delete news successful");
      })
      .catch(() => {
        errorAlert();
      });
  };

  const handleEditorChange = (e) => {
    setDescription(e.target.getContent());
  };

  return (
    <>
      <Modal
        destroyOnClose={true}
        title="Edit - News"
        visible={visible}
        okText="Update"
        onOk={onEditNews}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={onEditNews}
          >
            Update
          </Button>,
        ]}
      >
        <Select
          placeholder="Select Language"
          defaultValue={news.lang}
          onChange={(value) => setLang(value)}
        >
          <Select.Option value="id">Indonesia</Select.Option>
          <Select.Option value="en">English</Select.Option>
        </Select>
        <br />
        <br />
        <Input
          placeholder="Title"
          defaultValue={news.title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <br />
        <br />
        <Editor
          apiKey="xkovcsork8x0mfeg83j06rvwzqfjctwtx36jho2bsfq8yyak"
          initialValue={news.description}
          init={{
            oninit: "setPlainText",
            height: 300,
            menubar: false,
            plugins: ["advlist autolink lists link image", "paste"],
            toolbar: "undo redo | bullist numlist",
          }}
          onChange={handleEditorChange}
        />
      </Modal>
      <Modal
        destroyOnClose={true}
        title="Edit - News Image"
        visible={showUpdateImage}
        okText="Update image"
        onOk={onEditNewsImage}
        onCancel={() => setShowUpdateImage(false)}
        footer={[
          <Button key="back" onClick={() => setShowUpdateImage(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={onEditNewsImage}
          >
            Update image
          </Button>,
        ]}
      >
        <Upload
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          listType="picture"
          fileList={imageList}
          onChange={({ fileList }) => setImageList(removeErrorStatus(fileList))}
        >
          <Button>
            <UploadOutlined /> Upload
          </Button>
        </Upload>
      </Modal>
      <Card
        title={
          news.lang === "id" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <img src={image.indonesia} width="24px" alt="indonesia" />
              <p style={{ margin: "0" }}>Bahasa Indonesia</p>
            </div>
          ) : news.lang === "en" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <img src={image.english} width="24px" alt="indonesia" />
              <p style={{ margin: "0" }}>English</p>
            </div>
          ) : (
            <p style={{ margin: "0" }}>Choose Language</p>
          )
        }
        style={{ width: "100%" }}
        cover={<img alt="news" src={news.image} height={220} />}
        actions={[
          <EditOutlined onClick={() => setVisible(true)} key="edit" />,
          <FileImageOutlined
            onClick={() => setShowUpdateImage(true)}
            key="edit-image"
          />,
          <DeleteOutlined onClick={onDeleteNews} key="delete" />,
        ]}
      >
        <Meta title={news.title} />
        <div>{ReactHtmlParser(news.description)}</div>
      </Card>
    </>
  );
};

export default NewsCard;
