import React, { useState, useEffect } from "react";
import { Layout, Button } from "antd";

import axios from "../../../helpers/axios";
import { errorAlert } from "../../../helpers/notification";

import ContactForm from "./ContactForm";
import ContactCard from "./ContactCard";

import "../../../App.css";

const { Content, Header } = Layout;

const Contact = () => {
  const [visible, setVisible] = useState(false);
  const [contacts, setContacts] = useState([]);

  const getContacts = () => {
    axios
      .get("/contact")
      .then(({ data: { data } }) => {
        setContacts(data);
      })
      .catch(() => {
        errorAlert();
      });
  };

  useEffect(() => {
    getContacts();
  }, []);

  return (
    <Content
      style={{ margin: "80px 16px 0", overflow: "initial", background: "#FFF" }}
    >
      {contacts.length < 2 && (
        <Header style={{ padding: "0 16px", textAlign: "end" }}>
          <Button onClick={() => setVisible(true)}>Add Contact</Button>
        </Header>
      )}
      <ContactForm
        visible={visible}
        setVisible={setVisible}
        getContacts={getContacts}
      />
      <div
        style={{
          padding: "16px 0",
          display: "grid",
          gridTemplateColumns: "100%",
          justifyContent: "center",
        }}
      >
        {contacts.map((contact) => (
          <ContactCard
            key={contact.id}
            contact={contact}
            getContacts={getContacts}
          />
        ))}
      </div>
    </Content>
  );
};

export default Contact;
