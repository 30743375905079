import React, { useState } from "react";
import { Card, Modal, Input, Descriptions } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Editor } from "@tinymce/tinymce-react";
import ReactHtmlParser from "react-html-parser";

import axios from "../../../helpers/axios";
import { successAlert, errorAlert } from "../../../helpers/notification";
import image from "../../../constants/image";

const ContactCard = ({ contact, getContacts }) => {
  const token = localStorage.getItem("token");
  const [visible, setVisible] = useState(false);
  const [number, setNumber] = useState(contact.number);
  const [email, setEmail] = useState(contact.email);
  const [weekdays, setWeekdays] = useState(contact.weekdays);
  const [weekend, setWeekend] = useState(contact.weekend);
  const [description, setDescription] = useState(contact.description);
  const [address, setAddress] = useState(contact.address);
  // const [lang, setLang] = useState("");

  const onEditContact = () => {
    axios
      .put(
        `/contact/update/${contact.id}`,
        {
          number,
          email,
          weekdays,
          weekend,
          description,
          address,
          // lang
        },
        {
          headers: { authorization: token },
        }
      )
      .then(() => {
        setVisible(false);
        successAlert("Update contact successful");
        getContacts();
      })
      .catch(() => {
        errorAlert();
      });
  };

  // const onDeleteContact = () => {
  //   axios
  //     .delete(`/contact/delete/${contact.id}`, {
  //       headers: { authorization: token },
  //     })
  //     .then(() => {
  //       getContacts();
  //       successAlert("Delete contact successful");
  //     })
  //     .catch(() => {
  //       errorAlert();
  //     });
  // };

  return (
    <>
      <Modal
        destroyOnClose={true}
        title="Edit - Contact"
        visible={visible}
        okText="Update"
        onOk={onEditContact}
        onCancel={() => setVisible(false)}
      >
        {/* <Select
          placeholder="Select Language"
          defaultValue={contact.lang}
          onChange={(value) => setLang(value)}
        >
          <Select.Option value="id">Indonesia</Select.Option>
          <Select.Option value="en">English</Select.Option>
        </Select>
        <br />
        <br /> */}
        <Editor
          apiKey="xkovcsork8x0mfeg83j06rvwzqfjctwtx36jho2bsfq8yyak"
          initialValue={number}
          init={{
            oninit: "setPlainText",
            height: 150,
            menubar: false,
            plugins: ["advlist autolink lists link image", "paste"],
            toolbar: "undo redo | bullist numlist",
          }}
          onChange={(e) => setNumber(e.target.getContent())}
        />
        <br />
        <Editor
          apiKey="xkovcsork8x0mfeg83j06rvwzqfjctwtx36jho2bsfq8yyak"
          initialValue={email}
          init={{
            oninit: "setPlainText",
            height: 150,
            menubar: false,
            plugins: ["advlist autolink lists link image", "paste"],
            toolbar: "undo redo | bullist numlist",
          }}
          onChange={(e) => setEmail(e.target.getContent())}
        />
        <br />
        <Input
          placeholder="Weekdays Office Hour"
          value={weekdays}
          onChange={(e) => setWeekdays(e.target.value)}
        />
        <br />
        <br />
        <Input
          placeholder="Weekend Office Hour"
          value={weekend}
          onChange={(e) => setWeekend(e.target.value)}
        />
        <br />
        <br />
        <Input.TextArea
          placeholder="Description"
          value={description}
          rows={4}
          onChange={(e) => setDescription(e.target.value)}
        />
        <br />
        <br />
        <Editor
          apiKey="xkovcsork8x0mfeg83j06rvwzqfjctwtx36jho2bsfq8yyak"
          initialValue={address}
          init={{
            oninit: "setPlainText",
            height: 300,
            menubar: false,
            plugins: ["advlist autolink lists link image", "paste"],
            toolbar: "undo redo | bullist numlist",
          }}
          onChange={(e) => setAddress(e.target.getContent())}
        />
      </Modal>
      <Card
        style={{ width: "100%" }}
        actions={[
          <EditOutlined onClick={() => setVisible(true)} key="edit" />,
          // <DeleteOutlined onClick={onDeleteContact} key="delete" />,
        ]}
      >
        <Descriptions
          title={
            contact.lang === "id" ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <img src={image.indonesia} width="24px" alt="indonesia" />
                <p style={{ margin: "0" }}>Bahasa Indonesia</p>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <img src={image.english} width="24px" alt="indonesia" />
                <p style={{ margin: "0" }}>English</p>
              </div>
            )
          }
          layout="vertical"
        >
          <Descriptions.Item label="Telephone">
            <div>{ReactHtmlParser(contact.number)}</div>
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            <div>{ReactHtmlParser(contact.email)}</div>
          </Descriptions.Item>
          <Descriptions.Item label="Weekdays">
            {contact.weekdays}
          </Descriptions.Item>
          <Descriptions.Item label="Weekend">
            {contact.weekend}
          </Descriptions.Item>
          <Descriptions.Item label="Description">
            {contact.description}
          </Descriptions.Item>
          <Descriptions.Item label="Address">
            <div>{ReactHtmlParser(contact.address)}</div>
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </>
  );
};

export default ContactCard;
