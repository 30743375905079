import React, { useState } from "react";
import { Modal, Input, Button, Select } from "antd";
import { Editor } from "@tinymce/tinymce-react";

import axios from "../../../helpers/axios";
import {
  successAlert,
  warningAlert,
  errorAlert,
} from "../../../helpers/notification";

const AboutForm = ({ visible, setVisible, getCareers }) => {
  const token = localStorage.getItem("token");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [qualification, setQualification] = useState([]);
  const [lang, setLang] = useState("");

  const handleSubmit = () => {
    if (!title || !description || !qualification[0] || !lang) {
      if (!lang) {
        warningAlert("Language required");
      }
      if (!title) {
        warningAlert("Title required");
      }
      if (!description) {
        warningAlert("Description required");
      }
      if (!qualification[0]) {
        warningAlert("Qualifications required");
      }
    } else {
      axios
        .post(
          "/career",
          {
            title,
            description,
            qualification,
            lang,
          },
          { headers: { authorization: token } }
        )
        .then(() => {
          setVisible(false);
          setTitle("");
          setDescription("");
          successAlert("Add about successful");
          getCareers();
        })
        .catch(() => {
          errorAlert();
        });
    }
  };

  const onChangeQualification = (value, currentI) => {
    const newQualification = qualification.map((qlfctn, i) => {
      if (i === currentI) {
        return value;
      }

      return qlfctn;
    });

    setQualification(newQualification);
  };

  const onRemoveQualification = () => {
    const removeLast = qualification.filter(
      (_, i) => i !== qualification.length - 1
    );
    setQualification(removeLast);
  };

  const handleEditorChange = (e) => {
    setDescription(e.target.getContent());
  };

  return (
    <div>
      <Modal
        destroyOnClose={true}
        title="Add - Career"
        visible={visible}
        okText="Submit"
        onOk={handleSubmit}
        onCancel={() => setVisible(false)}
      >
        <Select
          placeholder="Select Language"
          onChange={(value) => setLang(value)}
        >
          <Select.Option value="id">Indonesia</Select.Option>
          <Select.Option value="en">English</Select.Option>
        </Select>
        <br />
        <br />
        <Input placeholder="Title" onChange={(e) => setTitle(e.target.value)} />
        <br />
        <br />
        <Editor
          apiKey="xkovcsork8x0mfeg83j06rvwzqfjctwtx36jho2bsfq8yyak"
          init={{
            oninit: "setPlainText",
            height: 300,
            menubar: false,
            plugins: ["advlist autolink lists link image", "paste"],
            toolbar: "undo redo | bullist numlist",
          }}
          onChange={handleEditorChange}
        />
        <br />
        {qualification.map((qlfctn, i) => (
          <Input
            style={{ marginBottom: "8px" }}
            placeholder={`Qualification ${i + 1}`}
            value={qlfctn}
            onChange={(e) => onChangeQualification(e.target.value, i)}
          />
        ))}
        <div style={{ textAlign: "end" }}>
          {qualification.length > 0 && (
            <Button
              style={{ marginRight: "8px" }}
              danger
              onClick={onRemoveQualification}
            >
              remove qualification
            </Button>
          )}
          <Button onClick={() => setQualification([...qualification, ""])}>
            Add qualification
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default AboutForm;
