import React, { useState, useEffect } from "react";
import { Layout, Button } from "antd";

import axios from "../../../helpers/axios";
import { errorAlert } from "../../../helpers/notification";

import NewsForm from "./NewsForm";
import NewsCard from "./NewsCard";

import "../../../App.css";

const { Content, Header } = Layout;

const About = () => {
  const [visible, setVisible] = useState(false);
  const [news, setNews] = useState([]);

  const getNews = () => {
    axios
      .get("/news")
      .then(({ data: { data } }) => {
        setNews(data);
      })
      .catch(() => {
        errorAlert();
      });
  };

  useEffect(() => {
    getNews();
  }, []);

  return (
    <Content
      style={{ margin: "80px 16px 0", overflow: "initial", background: "#FFF" }}
    >
      <Header style={{ padding: "0 16px", textAlign: "end" }}>
        <Button onClick={() => setVisible(true)}>Add News</Button>
      </Header>
      <NewsForm visible={visible} setVisible={setVisible} getNews={getNews} />
      <div
        style={{
          padding: "16px 0",
          display: "grid",
          gridTemplateColumns: "31.4% 31.4% 31.4%",
          justifyContent: "center",
          gridGap: "16px",
        }}
      >
        {news.map((newsData) => (
          <NewsCard key={newsData.id} news={newsData} getNews={getNews} />
        ))}
      </div>
    </Content>
  );
};

export default About;
