import React, { useState, useEffect } from "react";
import { Layout, Button, Pagination } from "antd";

import axios from "../../../helpers/axios";
import { errorAlert } from "../../../helpers/notification";

import ProductForm from "./ProductForm";
import ProductCard from "./ProductCard";

import "../../../App.css";

const { Content, Header } = Layout;

const Product = () => {
  const [visible, setVisible] = useState(false);
  const [products, setProducts] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(1);

  const getProducts = (currentPage = page) => {
    axios
      .get(`/product/page/${currentPage}/limit/6`)
      .then(({ data: { data, total } }) => {
        setTotalData(total);
        setProducts(data);
      })
      .catch(() => {
        errorAlert();
      });
  };

  const onNextOrPreviousPage = (currentPage) => {
    setPage(currentPage);
    getProducts(currentPage);
  };

  useEffect(() => {
    getProducts(page);
  }, [page]);

  return (
    <Content
      style={{ margin: "80px 16px 0", overflow: "initial", background: "#FFF" }}
    >
      <Header
        style={{
          padding: "0 16px",
          textAlign: "end",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Pagination
          current={page}
          total={totalData}
          pageSize={6}
          onChange={onNextOrPreviousPage}
        />
        <Button onClick={() => setVisible(true)}>Add product</Button>
      </Header>
      <ProductForm
        visible={visible}
        setVisible={setVisible}
        getProducts={getProducts}
      />
      <div
        style={{
          padding: "16px 0",
          display: "grid",
          gridTemplateColumns: "31.4% 31.4% 31.4%",
          justifyContent: "center",
          gridGap: "16px",
        }}
      >
        {products.map((product) => (
          <ProductCard
            key={product.id}
            product={product}
            getProducts={getProducts}
          />
        ))}
      </div>
    </Content>
  );
};

export default Product;
