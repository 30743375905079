import React, { useState } from "react";
import { Modal, Input, Button, Select } from "antd";
import { Editor } from "@tinymce/tinymce-react";

import axios from "../../../helpers/axios";
import {
  successAlert,
  warningAlert,
  errorAlert,
} from "../../../helpers/notification";

const AboutForm = ({ visible, setVisible, getTerms }) => {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [lang, setLang] = useState("");

  const handleSubmit = () => {
    if (!title || !description || !lang) {
      if (!lang) {
        warningAlert("Language required");
      }
      if (!title) {
        warningAlert("Title required");
      }
      if (!description) {
        warningAlert("Description required");
      }
    } else {
      setLoading(true);
      axios
        .post(
          "/terms",
          {
            title,
            description,
            lang,
          },
          { headers: { authorization: token } }
        )
        .then(() => {
          setTitle("");
          setDescription("");
          setLang("");
          setLoading(false);
          setVisible(false);
          getTerms();
          successAlert("Add terms successful");
        })
        .catch(() => {
          setLoading(false);
          errorAlert();
        });
    }
  };

  const handleEditorChange = (e) => {
    setDescription(e.target.getContent());
  };

  return (
    <div>
      <Modal
        destroyOnClose={true}
        title="Add - Terms"
        visible={visible}
        okText="Submit"
        onOk={handleSubmit}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleSubmit}
          >
            Submit
          </Button>,
        ]}
      >
        <Select
          placeholder="Select Language"
          onChange={(value) => setLang(value)}
        >
          <Select.Option value="id">Indonesia</Select.Option>
          <Select.Option value="en">English</Select.Option>
        </Select>
        <br />
        <br />
        <Input placeholder="Title" onChange={(e) => setTitle(e.target.value)} />
        <br />
        <br />
        <Editor
          apiKey="xkovcsork8x0mfeg83j06rvwzqfjctwtx36jho2bsfq8yyak"
          init={{
            oninit: "setPlainText",
            height: 300,
            menubar: false,
            plugins: ["advlist autolink lists link image", "paste"],
            toolbar: "undo redo | bullist numlist",
          }}
          onChange={handleEditorChange}
        />
      </Modal>
    </div>
  );
};

export default AboutForm;
