import React, { useState, useEffect } from "react";
import { Layout, Pagination, Collapse } from "antd";
import moment from "moment";
import { DeleteOutlined } from "@ant-design/icons";

import axios from "../../../helpers/axios";
import { errorAlert, successAlert } from "../../../helpers/notification";

import "../../../App.css";

const { Content, Header } = Layout;
const { Panel } = Collapse;

const Message = () => {
  const token = localStorage.getItem("token");
  const [messages, setMessages] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(1);

  const getMessages = (currentPage = page) => {
    axios
      .get(`/message/page/${currentPage}/limit/10`, {
        headers: { authorization: token },
      })
      .then(({ data: { data, total } }) => {
        setTotalData(total);
        setMessages(data);
      })
      .catch((e) => {
        errorAlert();
      });
  };

  const onDeleteMessage = (e, id) => {
    e.stopPropagation();
    axios
      .delete(`/message/delete/${id}`, {
        headers: { authorization: token },
      })
      .then(() => {
        getMessages();
        successAlert("Success delete message");
      })
      .catch(() => {
        errorAlert();
      });
  };

  const onNextOrPreviousPage = (currentPage) => {
    setPage(currentPage);
    getMessages(currentPage);
  };

  useEffect(() => {
    getMessages(page);
  }, [page]);

  return (
    <Content
      style={{ margin: "80px 16px 0", overflow: "initial", background: "#FFF" }}
    >
      <Header
        style={{
          padding: "0 16px",
          textAlign: "end",
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        <Pagination
          current={page}
          total={totalData}
          pageSize={10}
          onChange={onNextOrPreviousPage}
        />
      </Header>
      <div style={{ padding: "16px 0" }}>
        <Collapse accordion>
          {messages.map((message, i) => (
            <Panel
              header={`From ${message.name} - ${message.phone}`}
              key={i + 1}
              extra={
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "auto auto",
                    gridColumnGap: "12px",
                  }}
                >
                  <div>
                    {moment(message.createdAt).format("DD MMMM YYYY HH:mm")}
                  </div>
                  <div>
                    <DeleteOutlined
                      onClick={(e) => onDeleteMessage(e, message.id)}
                      key="delete"
                    />
                  </div>
                </div>
              }
            >
              <p>{message.message}</p>
            </Panel>
          ))}
        </Collapse>
      </div>
    </Content>
  );
};

export default Message;
