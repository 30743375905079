import React, { useState, useEffect } from "react";
import { Layout, Button } from "antd";

import axios from "../../../helpers/axios";
import { errorAlert } from "../../../helpers/notification";

import AboutForm from "./AboutForm";
import AboutCard from "./AboutCard";

import "../../../App.css";

const { Content, Header } = Layout;

const About = () => {
  const [visible, setVisible] = useState(false);
  const [abouts, setAbouts] = useState([]);

  const getAbouts = () => {
    axios
      .get("/about")
      .then(({ data: { data } }) => {
        setAbouts(data);
      })
      .catch(() => {
        errorAlert();
      });
  };

  useEffect(() => {
    getAbouts();
  }, []);

  return (
    <Content
      style={{ margin: "80px 16px 0", overflow: "initial", background: "#FFF" }}
    >
      <Header style={{ padding: "0 16px", textAlign: "end" }}>
        <Button onClick={() => setVisible(true)}>Add About</Button>
      </Header>
      <AboutForm
        visible={visible}
        setVisible={setVisible}
        getAbouts={getAbouts}
      />
      <div
        style={{
          padding: "16px 0",
          display: "grid",
          gridTemplateColumns: "31.4% 31.4% 31.4%",
          justifyContent: "center",
          gridGap: "16px",
        }}
      >
        {abouts.map((about) => (
          <AboutCard key={about.id} about={about} getAbouts={getAbouts} />
        ))}
      </div>
    </Content>
  );
};

export default About;
