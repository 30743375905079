import React from "react";
import { Layout, Menu } from "antd";
import {
  ReadOutlined,
  QuestionCircleOutlined,
  PictureOutlined,
  TrophyOutlined,
  UsergroupAddOutlined,
  MessageOutlined,
  HddOutlined,
  ContactsOutlined,
  LogoutOutlined,
  SafetyOutlined
} from "@ant-design/icons";
import { Route, Link, useParams, Redirect, useHistory } from "react-router-dom";

import "../../App.css";

import Admin from "./Admin";
import Product from "./Product";
import Message from "./Message";
import About from "./About";
import Banner from "./Banner";
import News from "./News";
import Career from "./Career";
import Contact from "./Contact";
import Terms from "./Terms";

const { Header, Sider } = Layout;

const Dashboard = () => {
  const role = localStorage.getItem("role");

  const params = useParams();
  const history = useHistory();

  const logout = () => {
    localStorage.removeItem("id");
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    localStorage.removeItem("role");
    localStorage.removeItem("token");
    history.push("/");
  };

  if (!localStorage.getItem("token")) {
    return <Redirect to="/" />;
  }

  return (
    <Layout>
      <Sider
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
        }}
      >
        <div className="logo" style={{ display: "flex", justifyContent: "center" }}>
          <img src={require("../../assets/cilia.png")} width="64%" alt="cilia" />
        </div>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={[params.menu]}>
          {role === "SUPERADMIN" && (
            <Menu.Item key="1" icon={<UsergroupAddOutlined />}>
              <Link to="/dashboard/1/admin">Admin</Link>
            </Menu.Item>
          )}
          <Menu.Item key="2" icon={<QuestionCircleOutlined />}>
            <Link to="/dashboard/2/about-us">About Us</Link>
          </Menu.Item>
          <Menu.Item key="3" icon={<PictureOutlined />}>
            <Link to="/dashboard/3/banner">Banner</Link>
          </Menu.Item>
          <Menu.Item key="4" icon={<ReadOutlined />}>
            <Link to="/dashboard/4/news">News</Link>
          </Menu.Item>
          <Menu.Item key="5" icon={<MessageOutlined />}>
            <Link to="/dashboard/5/inbox">Inbox</Link>
          </Menu.Item>
          <Menu.Item key="6" icon={<HddOutlined />}>
            <Link to="/dashboard/6/product">Product</Link>
          </Menu.Item>
          <Menu.Item key="7" icon={<TrophyOutlined />}>
            <Link to="/dashboard/7/career">Career</Link>
          </Menu.Item>
          <Menu.Item key="8" icon={<ContactsOutlined />}>
            <Link to="/dashboard/8/contact-us">Contact Us</Link>
          </Menu.Item>
          <Menu.Item key="9" icon={<SafetyOutlined />}>
            <Link to="/dashboard/9/terms">Terms</Link>
          </Menu.Item>
          <Menu.Item
            style={{ color: "red" }}
            onClick={logout}
            icon={<LogoutOutlined />}
          >
            Logout
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout
        className="site-layout"
        style={{ marginLeft: 200, background: "#FFF" }}
      >
        <Header
          style={{
            position: "fixed",
            zIndex: 1,
            width: "100%",
            height: "68px",
            background: "#012240",
          }}
        >
          <div style={{ color: "#FFF" }}>{params.current.split("-").join(" ").toUpperCase()}</div>
        </Header>
        {role === "SUPERADMIN" && (
          <Route exact path="/dashboard/1/admin">
            <Admin />
          </Route>
        )}
        <Route exact path="/dashboard/2/about-us">
          <About />
        </Route>
        <Route exact path="/dashboard/3/banner">
          <Banner />
        </Route>
        <Route exact path="/dashboard/4/news">
          <News />
        </Route>
        <Route exact path="/dashboard/5/inbox">
          <Message />
        </Route>
        <Route exact path="/dashboard/6/product">
          <Product />
        </Route>
        <Route exact path="/dashboard/7/career">
          <Career />
        </Route>
        <Route exact path="/dashboard/8/contact-us">
          <Contact />
        </Route>
        <Route exact path="/dashboard/9/terms">
          <Terms />
        </Route>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
