import React, { useState } from "react";
import { Modal, Input, Upload, Button, InputNumber, Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Editor } from "@tinymce/tinymce-react";

import axios from "../../../helpers/axios";
import {
  successAlert,
  warningAlert,
  errorAlert,
} from "../../../helpers/notification";

const AboutForm = ({ visible, setVisible, getAbouts }) => {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState(null);
  const [title, setTitle] = useState("");
  const [imageList, setImageList] = useState([]);
  const [description, setDescription] = useState("");
  const [lang, setLang] = useState("");

  const handleSubmit = () => {
    if (
      imageList.length === 0 ||
      !position ||
      !title ||
      !description ||
      !lang
    ) {
      if (!position) {
        warningAlert("Position required");
      }
      if (!lang) {
        warningAlert("Language required");
      }
      if (!title) {
        warningAlert("Title required");
      }
      if (imageList.length === 0) {
        warningAlert("Image required");
      }
      if (!description) {
        warningAlert("Description required");
      }
    } else {
      setLoading(true);
      const formData = new FormData();

      formData.append("position", position);
      formData.append("title", title);
      formData.append("image", imageList[0].originFileObj);
      formData.append("description", description);
      formData.append("lang", lang);

      axios
        .post("/about", formData, { headers: { authorization: token } })
        .then(() => {
          setPosition(1);
          setTitle("");
          setImageList([]);
          setDescription("");
          setLang("");
          setLoading(false);
          setVisible(false);
          getAbouts();
          successAlert("Add about successful");
        })
        .catch(() => {
          setLoading(false);
          errorAlert();
        });
    }
  };

  const removeErrorStatus = (rawFiles) => {
    const result = rawFiles.map((files) => ({ ...files, status: "done" }));
    return result;
  };

  const handleEditorChange = (e) => {
    setDescription(e.target.getContent());
  };

  return (
    <div>
      <Modal
        destroyOnClose={true}
        title="Add - About Us"
        visible={visible}
        okText="Submit"
        onOk={handleSubmit}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleSubmit}
          >
            Submit
          </Button>,
        ]}
      >
        <InputNumber
          min={false}
          placeholder="Position"
          onChange={(value) => setPosition(value)}
        />
        <br />
        <br />
        <Select
          placeholder="Select Language"
          onChange={(value) => setLang(value)}
        >
          <Select.Option value="id">Indonesia</Select.Option>
          <Select.Option value="en">English</Select.Option>
        </Select>
        <br />
        <br />
        <Input placeholder="Title" onChange={(e) => setTitle(e.target.value)} />
        <br />
        <br />
        <Upload
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          listType="picture"
          fileList={imageList}
          onChange={({ fileList }) => setImageList(removeErrorStatus(fileList))}
        >
          <Button>
            <UploadOutlined /> Image (required)
          </Button>
        </Upload>
        <br />
        <Editor
          apiKey="xkovcsork8x0mfeg83j06rvwzqfjctwtx36jho2bsfq8yyak"
          init={{
            oninit: "setPlainText",
            height: 300,
            menubar: false,
            plugins: ["advlist autolink lists link image", "paste"],
            toolbar: "undo redo | bullist numlist",
          }}
          onChange={handleEditorChange}
        />
      </Modal>
    </div>
  );
};

export default AboutForm;
