import React, { useState } from "react";
import { Modal, Input, Select } from "antd";

import axios from "../../../helpers/axios";
import { successAlert, errorAlert } from "../../../helpers/notification";

const AboutForm = ({ visible, setVisible, getContacts }) => {
  const token = localStorage.getItem("token");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [weekdays, setWeekdays] = useState("");
  const [weekend, setWeekend] = useState("");
  const [description, setDescription] = useState("");
  const [address, setAddress] = useState("");
  const [lang, setLang] = useState("");

  const handleSubmit = () => {
    axios
      .post(
        "/contact",
        {
          number,
          email,
          weekdays,
          weekend,
          description,
          address,
          lang,
        },
        { headers: { authorization: token } }
      )
      .then(() => {
        setVisible(false);
        setNumber("");
        setEmail("");
        setAddress("");
        successAlert("Add contact successful");
        getContacts();
      })
      .catch(() => {
        errorAlert();
      });
  };

  return (
    <div>
      <Modal
        destroyOnClose={true}
        title="Add - Contact"
        visible={visible}
        okText="Submit"
        onOk={handleSubmit}
        onCancel={() => setVisible(false)}
      >
        <Select
          placeholder="Select Language"
          onChange={(value) => setLang(value)}
        >
          <Select.Option value="id">Indonesia</Select.Option>
          <Select.Option value="en">English</Select.Option>
        </Select>
        <br />
        <br />
        <Input
          placeholder="Phone Number"
          onChange={(e) => setNumber(e.target.value)}
        />
        <br />
        <br />
        <Input
          type="email"
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <br />
        <br />
        <Input
          placeholder="Weekdays Office Hour"
          onChange={(e) => setWeekdays(e.target.value)}
        />
        <br />
        <br />
        <Input
          placeholder="Weekend Office Hour"
          onChange={(e) => setWeekend(e.target.value)}
        />
        <br />
        <br />
        <Input.TextArea
          placeholder="Description"
          rows={4}
          onChange={(e) => setDescription(e.target.value)}
        />
        <br />
        <br />
        <Input.TextArea
          placeholder="Address"
          rows={4}
          onChange={(e) => setAddress(e.target.value)}
        />
      </Modal>
    </div>
  );
};

export default AboutForm;
