import React, { useState } from "react";
import { Card, Modal, Input } from "antd";
import { EditOutlined, DeleteOutlined, LockOutlined } from "@ant-design/icons";

import axios from "../../../helpers/axios";
import { successAlert, errorAlert } from "../../../helpers/notification";

const { Meta } = Card;

const UserCard = ({ user, getUsers }) => {
  const token = localStorage.getItem("token");
  const [visible, setVisible] = useState(false);
  const [showEditPass, setShowEditPass] = useState(false);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");

  const onEditUser = () => {
    axios
      .put(
        `/user/update/${user.id}`,
        {
          name: name || user.name,
        },
        {
          headers: { authorization: token },
        }
      )
      .then(() => {
        setVisible(false);
        setName("");
        successAlert("Update admin successful");
        getUsers();
      })
      .catch(() => {
        errorAlert();
      });
  };

  const onEditUserPassword = () => {
    axios
      .put(
        `/user/update/${user.id}`,
        {
          password,
        },
        {
          headers: { authorization: token },
        }
      )
      .then(() => {
        setShowEditPass(false);
        setPassword("");
        successAlert("Update admin password successful");
        getUsers();
      })
      .catch(() => {
        errorAlert();
      });
  };

  const onDeleteUser = () => {
    axios
      .delete(`/user/delete/${user.id}`, {
        headers: { authorization: token },
      })
      .then(() => {
        getUsers();
        successAlert("Delete admin successful");
      })
      .catch(() => {
        errorAlert();
      });
  };

  return (
    <>
      <Modal
        destroyOnClose={true}
        title="Edit - Admin"
        visible={visible}
        okText="Update"
        onOk={onEditUser}
        onCancel={() => setVisible(false)}
      >
        <Input
          placeholder="Username"
          defaultValue={user.name}
          onChange={(e) => setName(e.target.value)}
        />
      </Modal>
      <Modal
        destroyOnClose={true}
        title="Edit - Admin Password"
        visible={showEditPass}
        okText="Update password"
        onOk={onEditUserPassword}
        onCancel={() => setShowEditPass(false)}
      >
        <Input.Password
          visibilityToggle
          placeholder="New password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </Modal>
      <Card
        style={{ width: "100%" }}
        actions={[
          <EditOutlined onClick={() => setVisible(true)} key="edit" />,
          <LockOutlined
            onClick={() => setShowEditPass(true)}
            key="edit-password"
          />,
          <DeleteOutlined onClick={onDeleteUser} key="delete" />,
        ]}
      >
        <Meta title={user.name} />
      </Card>
    </>
  );
};

export default UserCard;
