import React, { useState, useEffect } from "react";
import { Layout, Button } from "antd";

import axios from "../../../helpers/axios";
import { errorAlert } from "../../../helpers/notification";

import TermsForm from "./TermsForm";
import TermsCard from "./TermsCard";

import "../../../App.css";

const { Content, Header } = Layout;

const Terms = () => {
  const [visible, setVisible] = useState(false);
  const [terms, setTerms] = useState([]);

  const getTerms = () => {
    axios
      .get("/terms")
      .then(({ data: { data } }) => {
        setTerms(data);
      })
      .catch(() => {
        errorAlert();
      });
  };

  useEffect(() => {
    getTerms();
  }, []);

  return (
    <Content
      style={{ margin: "80px 16px 0", overflow: "initial", background: "#FFF" }}
    >
      {terms.length < 4 && (
        <Header style={{ padding: "0 16px", textAlign: "end" }}>
          <Button onClick={() => setVisible(true)}>Add Terms</Button>
        </Header>
      )}
      <TermsForm
        visible={visible}
        setVisible={setVisible}
        getTerms={getTerms}
      />
      <div
        style={{
          padding: "16px 0",
          display: "grid",
          gridTemplateColumns: "31.4% 31.4% 31.4%",
          justifyContent: "center",
          gridGap: "16px",
        }}
      >
        {terms.map((item) => (
          <TermsCard key={item.id} terms={item} getTerms={getTerms} />
        ))}
      </div>
    </Content>
  );
};

export default Terms;
