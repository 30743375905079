import React, { useState } from "react";
import { Card, Modal, Carousel, Upload, Button } from "antd";
import {
  // EditOutlined,
  FileImageOutlined,
  // DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import axios from "../../../helpers/axios";
import { successAlert, errorAlert } from "../../../helpers/notification";

const { Meta } = Card;

const BannerCard = ({ banner, getBanners }) => {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  // const [visible, setVisible] = useState(false);
  const [showUpdateImage, setShowUpdateImage] = useState(false);
  // const [name, setName] = useState("");
  // const [link, setLink] = useState("");
  const [desktopImage, setDesktopImage] = useState([]);
  const [mobileImage, setMobileImage] = useState([]);
  const [carouselThree, setCarouselThree] = useState([]);

  // const onEditBanner = () => {
  //   setLoading(true);
  //   axios
  //     .put(
  //       `/banner/update/${banner.id}`,
  //       {
  //         name: name || banner.name,
  //         link: link || banner.link,
  //       },
  //       {
  //         headers: { authorization: token },
  //       }
  //     )
  //     .then(() => {
  //       setName("");
  //       setLink("");
  //       setLoading(false);
  //       setVisible(false);
  //       getBanners();
  //       successAlert("Update banner successful");
  //     })
  //     .catch(() => {
  //       setLoading(false);
  //       errorAlert();
  //     });
  // };

  const onEditBannerImage = () => {
    setLoading(true);
    const formData = new FormData();

    formData.append("desktop_image", desktopImage[0]?.originFileObj || "");
    formData.append("mobile_image", mobileImage[0]?.originFileObj || "");
    formData.append("carousel_three", carouselThree[0]?.originFileObj || "");

    axios
      .put(`/banner/update-image/${banner.id}`, formData, {
        headers: { authorization: token },
      })
      .then(() => {
        setDesktopImage([]);
        setMobileImage([]);
        setLoading(false);
        setShowUpdateImage(false);
        getBanners();
        successAlert("Update banner image successful");
      })
      .catch(() => {
        setLoading(false);
        errorAlert();
      });
  };

  const removeErrorStatus = (rawFiles) => {
    const result = rawFiles.map((files) => ({ ...files, status: "done" }));
    return result;
  };

  // const onDeleteBanner = () => {
  //   axios
  //     .delete(`/banner/delete/${banner.id}`, {
  //       headers: { authorization: token },
  //     })
  //     .then(() => {
  //       getBanners();
  //       successAlert("Delete banner successful");
  //     })
  //     .catch(() => {
  //       errorAlert();
  //     });
  // };

  // const onHandleSelect = (value) => {
  //   setName(value);
  //   switch (value) {
  //     case "homepage":
  //       return setLink("/");
  //     case "about-us":
  //       return setLink("/about-us");
  //     case "products":
  //       return setLink("/products");
  //     case "news":
  //       return setLink("/news");
  //     case "career":
  //       return setLink("/career");
  //     case "contact-us":
  //       return setLink("/contact-us");
  //     case "contact-form":
  //       return setLink("/contact-form");
  //     default:
  //       return;
  //   }
  // };

  return (
    <>
      {/* <Modal
        destroyOnClose={true}
        title="Edit banner"
        visible={visible}
        okText="Update"
        onOk={onEditBanner}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={onEditBanner}
          >
            Update
          </Button>,
        ]}
      >
        <Select
          placeholder="Edit page"
          onChange={(value) => onHandleSelect(value)}
          style={{ width: "100%" }}
        >
          <Select.Option value="homepage">Homepage</Select.Option>
          <Select.Option value="about-us">About Us</Select.Option>
          <Select.Option value="products">Products</Select.Option>
          <Select.Option value="news">News</Select.Option>
          <Select.Option value="career">Career</Select.Option>
          <Select.Option value="contact-us">Contact Us</Select.Option>
          <Select.Option value="contact-form">Contact Form</Select.Option>
        </Select>
      </Modal> */}
      <Modal
        destroyOnClose={true}
        title="Edit - Banner Image"
        visible={showUpdateImage}
        okText="Update image"
        onOk={onEditBannerImage}
        onCancel={() => setShowUpdateImage(false)}
        footer={[
          <Button key="back" onClick={() => setShowUpdateImage(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={onEditBannerImage}
          >
            Update image
          </Button>,
        ]}
      >
        <Upload
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          listType="picture"
          fileList={desktopImage}
          onChange={({ fileList }) =>
            setDesktopImage(removeErrorStatus(fileList))
          }
        >
          <Button>
            <UploadOutlined />{" "}
            {banner.name === "homepage" ? "Image 1" : "Desktop Banner"}
          </Button>
        </Upload>
        <br />
        <Upload
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          listType="picture"
          fileList={mobileImage}
          onChange={({ fileList }) =>
            setMobileImage(removeErrorStatus(fileList))
          }
        >
          <Button>
            <UploadOutlined />{" "}
            {banner.name === "homepage" ? "Image 2" : "Mobile Banner"}
          </Button>
        </Upload>
        <br />
        {banner.name === "homepage" && (
          <Upload
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            listType="picture"
            fileList={carouselThree}
            onChange={({ fileList }) =>
              setCarouselThree(removeErrorStatus(fileList))
            }
          >
            <Button>
              <UploadOutlined /> Image 3
            </Button>
          </Upload>
        )}
      </Modal>
      <Card
        style={{ width: "100%" }}
        cover={
          <Carousel autoplay>
            <img
              alt="banner-desktop"
              src={banner.desktop_image}
              height={banner.name === "homepage" || banner.name === "contact-form" ? 520 : 140}
            />
            {banner.mobile_image && (
              <img
                alt="banner-mobile"
                src={banner.mobile_image}
                height={banner.name === "homepage" || banner.name === "contact-form"? 520 : 140}
              />
            )}
            {banner.carousel_three && (
              <img
                alt="carousel three"
                src={banner.carousel_three}
                height={banner.name === "homepage" || banner.name === "contact-form"? 520 : 140}
              />
            )}
          </Carousel>
        }
        actions={[
          // <EditOutlined onClick={() => setVisible(true)} key="edit" />,
          <FileImageOutlined
            onClick={() => setShowUpdateImage(true)}
            key="edit-image"
          />,
          // <DeleteOutlined onClick={onDeleteBanner} key="delete" />,
        ]}
      >
        <Meta title={banner.name} description={banner.link} />
      </Card>
    </>
  );
};

export default BannerCard;
