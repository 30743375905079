import React, { useState } from "react";
import { Card, Modal, Input, List, Divider, Button, Select } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Editor } from "@tinymce/tinymce-react";
import ReactHtmlParser from "react-html-parser";

import axios from "../../../helpers/axios";
import { successAlert, errorAlert } from "../../../helpers/notification";
import image from "../../../constants/image";

const { Meta } = Card;

const CareerCard = ({ career, getCareers }) => {
  const token = localStorage.getItem("token");
  const [visible, setVisible] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [qualification, setQualification] = useState(career.qualification);
  const [lang, setLang] = useState("");

  const onEditCareer = () => {
    axios
      .put(
        `/career/update/${career.id}`,
        {
          title: title || career.title,
          description: description || career.description,
          qualification: qualification || career.qualification,
          lang: lang || career.lang,
        },
        {
          headers: { authorization: token },
        }
      )
      .then(() => {
        setVisible(false);
        setTitle("");
        setDescription("");
        successAlert("Update career successful");
        getCareers();
      })
      .catch(() => {
        errorAlert();
      });
  };

  const onDeleteCareer = () => {
    axios
      .delete(`/career/delete/${career.id}`, {
        headers: { authorization: token },
      })
      .then(() => {
        getCareers();
        successAlert("Delete career successful");
      })
      .catch(() => {
        errorAlert();
      });
  };

  const onRemoveQualification = () => {
    const removeLast = qualification.filter(
      (_, i) => i !== qualification.length - 1
    );
    setQualification(removeLast);
  };

  const onChangeQualification = (value, currentI) => {
    const newQualification = qualification.map((qlfctn, i) => {
      if (i === currentI) {
        return value;
      }

      return qlfctn;
    });

    setQualification(newQualification);
  };

  const handleEditorChange = (e) => {
    setDescription(e.target.getContent());
  };

  return (
    <>
      <Modal
        destroyOnClose={true}
        title="Edit - Career"
        visible={visible}
        okText="Update"
        onOk={onEditCareer}
        onCancel={() => setVisible(false)}
      >
        <Select
          placeholder="Select Language"
          defaultValue={career.lang}
          onChange={(value) => setLang(value)}
        >
          <Select.Option value="id">Indonesia</Select.Option>
          <Select.Option value="en">English</Select.Option>
        </Select>
        <br />
        <br />
        <Input
          placeholder="Title"
          defaultValue={career.title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <br />
        <br />
        <Editor
          apiKey="xkovcsork8x0mfeg83j06rvwzqfjctwtx36jho2bsfq8yyak"
          initialValue={career.description}
          init={{
            oninit: "setPlainText",
            height: 300,
            menubar: false,
            plugins: ["advlist autolink lists link image", "paste"],
            toolbar: "undo redo | bullist numlist",
          }}
          onChange={handleEditorChange}
        />
        <br />
        {qualification.map((qlfctn, i) => (
          <Input
            style={{ marginBottom: "8px" }}
            placeholder={`Qualification ${i + 1}`}
            value={qlfctn}
            onChange={(e) => onChangeQualification(e.target.value, i)}
          />
        ))}
        <div style={{ textAlign: "end" }}>
          {qualification.length > 0 && (
            <Button
              style={{ marginRight: "8px" }}
              danger
              onClick={onRemoveQualification}
            >
              remove qualification
            </Button>
          )}
          <Button onClick={() => setQualification([...qualification, ""])}>
            Add qualification
          </Button>
        </div>
      </Modal>
      <Card
        title={
          career.lang === "id" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <img src={image.indonesia} width="24px" alt="indonesia" />
              <p style={{ margin: "0" }}>Bahasa Indonesia</p>
            </div>
          ) : career.lang === "en" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <img src={image.english} width="24px" alt="indonesia" />
              <p style={{ margin: "0" }}>English</p>
            </div>
          ) : (
            <p style={{ margin: "0" }}>Choose Language</p>
          )
        }
        style={{ width: "100%" }}
        actions={[
          <EditOutlined onClick={() => setVisible(true)} key="edit" />,
          <DeleteOutlined onClick={onDeleteCareer} key="delete" />,
        ]}
      >
        <Meta title={career.title} />
        <div>{ReactHtmlParser(career.description)}</div>
        <Divider />
        <List
          size="small"
          bordered
          dataSource={career.qualification}
          renderItem={(item) => <List.Item>{item}</List.Item>}
        />
      </Card>
    </>
  );
};

export default CareerCard;
