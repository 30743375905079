import React, { useState, useEffect } from "react";
import { Layout, Button } from "antd";

import axios from "../../../helpers/axios";
import { errorAlert } from "../../../helpers/notification";

import BannerForm from "./BannerForm";
import BannerCard from "./BannerCard";

import "../../../App.css";

const { Content, Header } = Layout;

const Banner = () => {
  const [visible, setVisible] = useState(false);
  const [banners, setBanners] = useState([]);

  const getBanners = () => {
    axios
      .get("/banner")
      .then(({ data: { data } }) => {
        setBanners(data);
      })
      .catch(() => {
        errorAlert();
      });
  };

  useEffect(() => {
    getBanners();
  }, []);

  return (
    <Content
      style={{ margin: "80px 16px 0", overflow: "initial", background: "#FFF" }}
    >
      {banners.length < 7 && (
        <Header style={{ padding: "0 16px", textAlign: "end" }}>
          <Button onClick={() => setVisible(true)}>Add Banner</Button>
        </Header>
      )}
      <BannerForm
        visible={visible}
        setVisible={setVisible}
        getBanners={getBanners}
      />
      <div
        style={{
          padding: 24,
          textAlign: "center",
          display: "grid",
          gridTemplateColumns: "100%",
          gridRowGap: "32px",
        }}
      >
        {banners.map((banner) => (
          <BannerCard key={banner.id} banner={banner} getBanners={getBanners} />
        ))}
      </div>
    </Content>
  );
};

export default Banner;
