import React from "react";
import { Switch, Route } from "react-router-dom";

import Login from "./Login/Login";
import Dashboard from "./Dashboard/Dashboard";

const Components = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Login />
      </Route>
      <Route exact path="/dashboard/:menu/:current">
        <Dashboard />
      </Route>
    </Switch>
  );
};

export default Components;
