import React, { useState } from "react";
import { Modal, Input } from "antd";

import axios from "../../../helpers/axios";
import {
  successAlert,
  warningAlert,
  errorAlert,
} from "../../../helpers/notification";

const AboutForm = ({ visible, setVisible, getUsers }) => {
  const token = localStorage.getItem("token");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = () => {
    if (!name || !password) {
      if (!name) {
        warningAlert("Username required");
      }
      if (!password) {
        warningAlert("Password required");
      }
    } else {
      axios
        .post(
          "/user/register",
          {
            name,
            password,
          },
          { headers: { authorization: token } }
        )
        .then(({ data }) => {
          if (data.code === 400) {
            data.error.errors.forEach((error) => {
              warningAlert(error.message);
            });
          } else {
            setVisible(false);
            setName("");
            setPassword("");
            successAlert("Add Admin successful");
            getUsers();
          }
        })
        .catch(() => {
          errorAlert();
        });
    }
  };

  return (
    <div>
      <Modal
        destroyOnClose={true}
        title="Add - Admin"
        visible={visible}
        okText="Submit"
        onOk={handleSubmit}
        onCancel={() => setVisible(false)}
      >
        <Input
          placeholder="Username"
          value={name}
          onChange={(e) =>
            setName(e.target.value.split(" ").join("").toLowerCase())
          }
        />
        <br />
        <br />
        <Input.Password
          placeholder="Password"
          visibilityToggle
          onChange={(e) => setPassword(e.target.value)}
        />
      </Modal>
    </div>
  );
};

export default AboutForm;
