import React, { useState } from "react";
import { Card, Modal, Input, Carousel, Upload, Button, Select } from "antd";
import { Editor } from "@tinymce/tinymce-react";
import {
  EditOutlined,
  DeleteOutlined,
  FileImageOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import ReactHtmlParser from "react-html-parser";

import axios from "../../../helpers/axios";
import { successAlert, errorAlert } from "../../../helpers/notification";
import image from "../../../constants/image";

const { Meta } = Card;

const ProductCard = ({ product, getProducts }) => {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showUpdateImage, setShowUpdateImage] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [lang, setLang] = useState("");
  const [imageList, setImageList] = useState([]);
  const [thumbnailList, setThumbnailList] = useState([]);

  const onEditProduct = () => {
    setLoading(true);
    axios
      .put(
        `/product/update/${product.id}`,
        {
          title: title || product.title,
          description: description || product.description,
          lang: lang || product.lang,
        },
        {
          headers: { authorization: token },
        }
      )
      .then(() => {
        setTitle("");
        setDescription("");
        setLang("");
        setLoading(false);
        setVisible(false);
        getProducts();
        successAlert("Update product successful");
      })
      .catch(() => {
        setLoading(false);
        errorAlert();
      });
  };

  const onEditProductImage = () => {
    setLoading(true);
    const formData = new FormData();

    formData.append("image", imageList[0]?.originFileObj || "");
    formData.append("thumbnail", thumbnailList[0]?.originFileObj || "");

    axios
      .put(`/product/update-image/${product.id}`, formData, {
        headers: { authorization: token },
      })
      .then(() => {
        setImageList([]);
        setThumbnailList([]);
        setLoading(false);
        setShowUpdateImage(false);
        getProducts();
        successAlert("Update product image successful");
      })
      .catch(() => {
        setLoading(false);
        errorAlert();
      });
  };

  const removeErrorStatus = (rawFiles) => {
    const result = rawFiles.map((files) => ({ ...files, status: "done" }));
    return result;
  };

  const onDeleteProduct = () => {
    axios
      .delete(`/product/delete/${product.id}`, {
        headers: { authorization: token },
      })
      .then(() => {
        getProducts();
        successAlert("Delete product successful");
      })
      .catch(() => {
        errorAlert();
      });
  };

  const handleEditorChange = (e) => {
    setDescription(e.target.getContent());
  };

  return (
    <>
      <Modal
        destroyOnClose={true}
        title="Edit - Product"
        visible={visible}
        okText="Update"
        onOk={onEditProduct}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={onEditProduct}
          >
            Update
          </Button>,
        ]}
      >
        <Select
          placeholder="Select Language"
          defaultValue={product.lang}
          onChange={(value) => setLang(value)}
        >
          <Select.Option value="id">Indonesia</Select.Option>
          <Select.Option value="en">English</Select.Option>
        </Select>
        <br />
        <br />
        <Input
          placeholder="Title"
          defaultValue={product.title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <br />
        <br />
        <Editor
          apiKey="xkovcsork8x0mfeg83j06rvwzqfjctwtx36jho2bsfq8yyak"
          initialValue={product.description}
          init={{
            oninit: "setPlainText",
            height: 300,
            menubar: false,
            plugins: ["advlist autolink lists link image", "paste"],
            toolbar: "undo redo | bullist numlist",
          }}
          onChange={handleEditorChange}
        />
      </Modal>
      <Modal
        destroyOnClose={true}
        title="Edit - Product Image"
        visible={showUpdateImage}
        okText="Update image"
        onOk={onEditProductImage}
        onCancel={() => setShowUpdateImage(false)}
        footer={[
          <Button key="back" onClick={() => setShowUpdateImage(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={onEditProductImage}
          >
            Update image
          </Button>,
        ]}
      >
        <Upload
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          listType="picture"
          fileList={imageList}
          onChange={({ fileList }) => setImageList(removeErrorStatus(fileList))}
        >
          <Button>
            <UploadOutlined /> Image
          </Button>
        </Upload>
        <br />
        <Upload
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          listType="picture"
          fileList={thumbnailList}
          onChange={({ fileList }) =>
            setThumbnailList(removeErrorStatus(fileList))
          }
        >
          <Button>
            <UploadOutlined /> Thumbnail
          </Button>
        </Upload>
        <br />
      </Modal>
      <Card
        title={
          product.lang === "id" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <img src={image.indonesia} width="24px" alt="indonesia" />
              <p style={{ margin: "0" }}>Bahasa Indonesia</p>
            </div>
          ) : product.lang === "en" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <img src={image.english} width="24px" alt="indonesia" />
              <p style={{ margin: "0" }}>English</p>
            </div>
          ) : (
            <p style={{ margin: "0" }}>Choose Language</p>
          )
        }
        style={{ width: "100%" }}
        cover={
          <Carousel autoplay>
            <img alt="product" src={product.image} height={220} />
            {product.thumbnail && (
              <img
                alt="product-thumbnail"
                src={product.thumbnail}
                height={220}
              />
            )}
          </Carousel>
        }
        actions={[
          <EditOutlined onClick={() => setVisible(true)} key="edit" />,
          <FileImageOutlined
            onClick={() => setShowUpdateImage(true)}
            key="edit-image"
          />,
          <DeleteOutlined onClick={onDeleteProduct} key="delete" />,
        ]}
      >
        <Meta title={product.title} />
        <div>{ReactHtmlParser(product.description)}</div>
      </Card>
    </>
  );
};

export default ProductCard;
