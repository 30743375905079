import React, { useState, useEffect } from "react";
import { Layout, Button, Modal, Input } from "antd";

import axios from "../../../helpers/axios";
import { errorAlert, successAlert } from "../../../helpers/notification";

import AdminForm from "./AdminForm";
import AdminCard from "./AdminCard";

import "../../../App.css";

const { Content, Header } = Layout;

const User = () => {
  const token = localStorage.getItem("token");
  const id = localStorage.getItem("id");
  const [visible, setVisible] = useState(false);
  const [showEditPass, setShowEditPass] = useState(false);
  const [password, setPassword] = useState("");
  const [users, setUsers] = useState([]);

  const getUsers = () => {
    axios
      .get("/user", {
        headers: { authorization: localStorage.getItem("token") },
      })
      .then(({ data: { data } }) => {
        setUsers(data);
      })
      .catch(() => {
        errorAlert();
      });
  };

  const onEditUserPassword = () => {
    axios
      .put(
        `/user/update/${id}`,
        {
          password,
        },
        {
          headers: { authorization: token },
        }
      )
      .then(() => {
        setShowEditPass(false);
        setPassword("");
        successAlert("Update superadmin password successful");
        getUsers();
      })
      .catch(() => {
        errorAlert();
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Content
      style={{ margin: "80px 16px 0", overflow: "initial", background: "#FFF" }}
    >
      <Modal
        destroyOnClose={true}
        title="Edit - Admin Password"
        visible={showEditPass}
        okText="Update password"
        onOk={onEditUserPassword}
        onCancel={() => setShowEditPass(false)}
      >
        <Input.Password
          visibilityToggle
          placeholder="New password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </Modal>
      <Header style={{ padding: "0 16px", textAlign: "end" }}>
        {localStorage.getItem("role") === "SUPERADMIN" && (
          <Button
            style={{ marginRight: "8px" }}
            onClick={() => setShowEditPass(true)}
          >
            Change Password
          </Button>
        )}
        <Button onClick={() => setVisible(true)}>Add Admin</Button>
      </Header>
      <AdminForm
        visible={visible}
        setVisible={setVisible}
        getUsers={getUsers}
      />
      <div
        style={{
          padding: "16px 0",
          display: "grid",
          gridTemplateColumns: "31.4% 31.4% 31.4%",
          justifyContent: "center",
          gridGap: "16px",
        }}
      >
        {users.map((user) => (
          <AdminCard key={user.id} user={user} getUsers={getUsers} />
        ))}
      </div>
    </Content>
  );
};

export default User;
