import React, { useState } from "react";
import { Card, Modal, Input, Button, Select } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Editor } from "@tinymce/tinymce-react";
import ReactHtmlParser from "react-html-parser";

import axios from "../../../helpers/axios";
import { successAlert, errorAlert } from "../../../helpers/notification";
import image from "../../../constants/image";

const { Meta } = Card;

const TermsCard = ({ terms, getTerms }) => {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [lang, setLang] = useState("");

  const onEditTerms = () => {
    setLoading(true);
    axios
      .put(
        `/terms/update/${terms.id}`,
        {
          title: title || terms.title,
          description: description || terms.description,
          lang: lang || terms.lang,
        },
        {
          headers: { authorization: token },
        }
      )
      .then(() => {
        setTitle("");
        setDescription("");
        setLoading(false);
        setVisible(false);
        setLang("");
        getTerms();
        successAlert("Update terms successful");
      })
      .catch(() => {
        setLoading(false);
        errorAlert();
      });
  };

  // const onDeleteTerms = () => {
  //   axios
  //     .delete(`/terms/delete/${terms.id}`, {
  //       headers: { authorization: token },
  //     })
  //     .then(() => {
  //       getTerms();
  //       successAlert("Delete terms successful");
  //     })
  //     .catch(() => {
  //       errorAlert();
  //     });
  // };

  const handleEditorChange = (e) => {
    setDescription(e.target.getContent());
  };

  return (
    <>
      <Modal
        destroyOnClose={true}
        title="Edit - Terms"
        visible={visible}
        okText="Update"
        onOk={onEditTerms}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={onEditTerms}
          >
            Update
          </Button>,
        ]}
      >
        <Select
          disabled
          placeholder="Select Language"
          defaultValue={terms.lang}
          onChange={(value) => setLang(value)}
        >
          <Select.Option value="id">Indonesia</Select.Option>
          <Select.Option value="en">English</Select.Option>
        </Select>
        <br />
        <br />
        <Input
          disabled
          placeholder="Title"
          defaultValue={terms.title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <br />
        <br />
        <Editor
          apiKey="xkovcsork8x0mfeg83j06rvwzqfjctwtx36jho2bsfq8yyak"
          initialValue={terms.description}
          init={{
            oninit: "setPlainText",
            height: 300,
            menubar: false,
            plugins: ["advlist autolink lists link image", "paste"],
            toolbar: "undo redo | bullist numlist",
          }}
          onChange={handleEditorChange}
        />
      </Modal>
      <Card
        title={
          terms.lang === "id" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <img src={image.indonesia} width="24px" alt="indonesia" />
              <p style={{ margin: "0" }}>Bahasa Indonesia</p>
            </div>
          ) : terms.lang === "en" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <img src={image.english} width="24px" alt="indonesia" />
              <p style={{ margin: "0" }}>English</p>
            </div>
          ) : (
            <p style={{ margin: "0" }}>Choose Language</p>
          )
        }
        style={{ width: "100%" }}
        actions={[
          <EditOutlined onClick={() => setVisible(true)} key="edit" />,
          // <DeleteOutlined onClick={onDeleteTerms} key="delete" />,
        ]}
      >
        <Meta title={terms.title} description={terms.position} />
        <div>{ReactHtmlParser(terms.description)}</div>
      </Card>
    </>
  );
};

export default TermsCard;
